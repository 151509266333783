<template>
  <div class="register" ref="register" v-if="$route.query.Version !== 'ONESTOP'">
    <div style="
        width: 100%;
        font-size: 46px;
        color: white;
      ">
      <div v-if="isShowdiy" class="diyLogo">
        <img :src="diyConfig.LogoImage" alt="LOGO图片">
        <img :src="diyConfig.HeaderImage" alt="文字图片">
      </div>
      <div v-else style="position: fixed; z-index: 1; top: 5%; left: 5%;">
        <img src="../../src/assets/image/loginPic1.png" style="width: 60px;height: 60px;">
        <span style="vertical-align: top;margin-left: 10px;">智能随访机器人大数据平台</span>
      </div>
    </div>
    <div class="bgImg">
      <img :src="diyConfig.BodyImage" alt="背景图片" v-if="isShowdiy" class="diyImg">
      <img src="../assets/image/loginbgcPic.jpg" style="height: 100vh; position: static; width: 100%;" alt="背景图片"
           :draggable="false" v-else>
      <div class="box" style="padding: 3% 40px;">
        <div class="register-border">
          <span class="span">系统登录</span>
          <div class="text-box">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
              <el-form-item prop="username">
                <el-input placeholder="请输入用户名" v-model="ruleForm.username" class="login-user">
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input placeholder="请输入密码" v-model="ruleForm.password" show-password class="login-lock">
                </el-input>
              </el-form-item>
              <el-form-item prop="Captcha">
                <div style="position: relative">
                  <el-input type="text" v-model="ruleForm.Captcha" style="background-color: transparent; border: 0"
                            placeholder="请输入验证码" class="login-ma">
                  </el-input>
                  <img :src="codeImg" alt="" class="code" @click="refreshCode">
                  <!-- <span v-html="ruleForm.Pin" style="color:#333;font-size: 18px;"></span> -->
                </div>
              </el-form-item>
            </el-form>
            <p style="margin-bottom: 20px;color: #666;cursor:pointer;" @click="Forgetthesecret">忘记密码？</p>
            <el-button class="button" :loading="loading" @click="getUserLogin">登录</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <canvas id="canv" style="width: 100%; height: 100%; overflow: hidden"></canvas> -->

    <div v-if="isShowdiy" style="width:100%;;margin-top: 40px; text-align: center; font-size: 14px;color: #999;">
      <p>Copyright © 2016-2022 SJS TECH. All Rights Reserved.</p>
      <a href="http://beian.miit.gov.cn" style="color: #999;">鄂ICP备15019034号-5</a>
      <p>{{ diyConfig.RootText }}</p>
    </div>
    <div v-else style="width:400px;position: absolute;bottom: 2%;left: 50%;margin-left: -200px;text-align: center;"
         class="footer">
      <p>Copyright © 2016-2022 SJS TECH. All Rights Reserved.</p>
      <a href="http://beian.miit.gov.cn" style="color: white;">鄂ICP备15019034号-5</a>
      <p>武汉赛捷思科技有限公司 版权所有</p>
    </div>
  </div>
</template>
<script>
import {local, session} from "@/utils/storage";
import apiLogin from "@/api/login";

export default {
  data() {
    var checkname = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("用户名为必填项"));
      } else {
        callback();
      }
    };
    var checkpwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码为必填项"));
      } else {
        callback();
      }
    };
    var checkCaptcha = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(" 验证码为必填项"));
      } else {
        callback();
      }
    };
    return {
      imgUrl: "",
      ruleForm: {
        username: "",
        password: "",
        Captcha: "",
        Pin: "",
        SecretKey: ""
      },
      rules: {
        username: [{validator: checkname, trigger: "blur"}],
        password: [{validator: checkpwd, trigger: "blur"}],
        Captcha: [{validator: checkCaptcha, trigger: "blur"}],
      },
      isShowdiy: this.$route.query.id ? true : false,
      Captcha: "",
      username: "",
      password: "",
      projectId: "",
      sliders: [],
      value: "",
      dialogVisible: false,
      loading: false,
      diyConfig: {
        LogoImage: "",
        HeaderImage: "",
        BodyImage: "",
        RootText: ""
      },
      codeImg: "",
    };
  },
  created() {
    if (this.isShowdiy) {
      this.$store.state.LOADING = true
      this.getIdyLogin()
    }
    this.pinImg()
    session.clear()
    local.clear();
    window.addEventListener('keydown', this.handkeyCode, true)
  },
  methods: {
    async getIdyLogin() {
      try {
        let res = await apiLogin.diyLogin(this.$route.query.id)
        if (res.data.Status !== 1) return
        let config = res.data.Data.config.pageConfig
        this.diyConfig.LogoImage = config?.logoImageUrl
        this.diyConfig.HeaderImage = config?.headerImageUrl
        this.diyConfig.BodyImage = config?.bodyImageUrl
        this.diyConfig.RootText = config?.rootText
        let headerText = res.data.Data.config.pageConfig.headerText || '智能随访机器人大数据平台'
        let logo = res.data.Data.config.pageConfig.logoImageUrl
        document.title = headerText
        local.set('headerText', headerText)
        local.set('logo', logo)
      } catch (error) {
        this.isShowdiy = false
      }
    },
    handkeyCode(e) {
      if (e.keyCode === 13) {
        this.getUserLogin();
        window.removeEventListener('keydown', this.handkeyCode, true);
      }
    },
    pinImg() {
      apiLogin.getImg().then(res => {
        this.codeImg = res.data.Pin;
        this.ruleForm.SecretKey = res.data.SecretKey
      })
    },
    Forgetthesecret() {
      this.$router.push({name: "VerificationCode"})
    },
    changeValue(key) {
      this.projectId = key;
      this.getUserLogin();
    },
    getUserLogin() {
      let parame = {
        LoginText: this.ruleForm.username,
        Password: this.ruleForm.password,
        Pin: this.ruleForm.Captcha,
        SecretKey: this.ruleForm.SecretKey
      };
      if (this.projectId) {
        parame.ProjectId = this.projectId;
      }
      this.submintLogin(parame);
    },
    /* 图形验证码 */
    refreshCode() {
      // this.imgUrl = ""
      this.pinImg()
    },
    undefinedToNullChar(obj) {
      for (let key in obj) {
        if (obj[key] === 'undefined') {
          obj[key] = '';
        }
      }
      return obj;
    },
    submintLogin(parame) {
      if (
          this.ruleForm.Captcha == "" ||
          this.ruleForm.password === "" ||
          this.ruleForm.username === ""
      ) {
        this.refreshCode()
        this.$message.error("请输入验证信息");
        return false;
      }
      this.loading = true
      apiLogin.login(parame).then(res => {
        if (res.data.Status == 1) {
          local.set("LoadingFalse", true);
          local.set("Token", res.data.Data.AccessToken);
          local.set("UserId", res.data.Data.UserId);
          local.set("RoleType", res.data.Data.RoleType);
          local.set("UserName", res.data.Data.Name);
          local.set('OrganizationName', res.data.Data.OrganizationName)
          local.set("currentOrganizationId", res.data.Data.OrganizationId);
          local.set("UnitName", res.data.Data.UnitName);
          local.set("TitleName", res.data.Data.TitleName);
          local.set("Sex", res.data.Data.Sex);
          if (res.data.Data.PageConfig) {
            let pageConfig = JSON.parse(res.data.Data.PageConfig)
            pageConfig = this.undefinedToNullChar(pageConfig) //将undefineda转为''
            local.set("headerText", pageConfig.headerText)
            local.set("logo", pageConfig.logoImageUrl)
            local.set("layout", pageConfig.layout)
            local.set("centerText1", pageConfig.centerText1)
            local.set("centerText2", pageConfig.centerText2)
            this.$store.state.menuFlag = local.get('layout') === 'LTOR'
          }
          this.$message.success("登录成功");
          this.$router.push("/HomePage");
          this.loading = false
          this.NoPagingOrganization()
        } else {
          this.$message.error(res.data.Message);
          this.refreshCode()
          this.loading = false
        }
      }).catch(() => {
        this.refreshCode()
        this.loading = false
      })
    },
    async centerLogin(data) {
      let {access_token, refresh_token} = data

      access_token = access_token.split(' ')[1]
      refresh_token = refresh_token.split(' ')[1]
      console.log(data)
      local.set("LoadingFalse", true);
      local.set("Token", access_token);
      local.set("refresh_token", refresh_token);
      let res = await apiLogin.useTokengetInfo()
      local.set("UserId", res.data.Data.UserId);
      local.set("RoleType", res.data.Data.RoleType);
      local.set("UserName", res.data.Data.Name);
      local.set('OrganizationName', res.data.Data.OrganizationName)
      local.set("currentOrganizationId", res.data.Data.OrganizationId);
      local.set("UnitName", res.data.Data.UnitName);
      local.set("TitleName", res.data.Data.TitleName);
      local.set("Sex", res.data.Data.Sex);


      this.$router.push("/HomePage");
    }
  },
  mounted() {
    window.addEventListener('message', e => {
      const {data} = e
      if (data.type === 'center') {
        this.centerLogin(data.data)
      }
    })
  }
};
</script>
<style lang='scss' scoped>
:deep(.el-form-item) {
  margin-bottom: 4%;
}

.diyLogo {
  height: 65%;
  margin: 20px;
  display: flex;
  align-items: center;

  img {
    margin: 0 10px;
    transition: 0.3s;
  }

  & > img:first-child {
    margin-left: 8%;
  }
}

.code {
  text-align: center;
  width: 150px;
  // background-image: linear-gradient(#FF0000,#FFF200, #1E9600);
  background: linear-gradient(35deg, #CCFFFF, #FFCCCC)
}

.register {
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;

  .bgImg {
    width: 100%;
    // height: 100vh;
    position: relative;

    .diyImg {
      width: 100%;
      height: 55%;
    }

    .box {
      transition: 0.3s;
      position: absolute;
      width: 20%;
      max-height: 80%;
      top: 50%;
      transform: translateY(-50%);
      right: 10%;
      background: #ffffff;
      border-radius: 13px;
      padding: 1%;

      .title {
        img {
          display: block;
          margin: 0 auto;
          object-fit: cover;
        }
      }

      .register-border {
        transition: 0.3s;

        .span {
          display: block;
          width: 100%;
          margin-bottom: 5%;
          font-size: 24px;
          color: #606266;
          line-height: 51px;
          text-align: center;
        }

        div.forget {
          display: flex;
          width: 80%;
          margin: 0px auto;
          justify-content: space-between;

          span {
            transform: translateY(1%);
            font-size: 18px;
            cursor: pointer;
          }
        }

        .text-box {

          ::v-deep .el-input {
            width: 100%;
            height: 50px;
            border-radius: 4px;

            .el-input__inner {
              border: 1px solid #cfcfcf;
              padding-left: 32px;
              font-size: 18px;
              height: 50px;
            }

            .el-input__prefix {
              color: rgb(32, 163, 245);

              .el-input__icon {
                font-size: 14px;
                line-height: 35px;
                margin-top: -2px;
              }
            }
          }
        }

        .button {
          width: 100%;
          height: 51px;
          font-size: 18px;
          color: #FFFFFF;
          font-weight: 400;
          background: linear-gradient(135deg, #65B4FF 0%, #4057E8 100%);
        }
      }
    }
  }

  ::v-deep .el-dialog {
    ::v-deep .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      .el-select {
        width: 100%;
      }
    }
  }
}

.code {
  position: absolute;
  top: 18%;
  right: 1%;
  cursor: pointer;
}

::v-deep .el-input.login-user .el-input__inner {
  padding-top: 4px;
  background: url("../assets/image/loginuser.png") no-repeat 14px center;
}

::v-deep .el-input.login-lock .el-input__inner {
  padding-top: 2px;
  background: url("../assets/image/loginlock.png") no-repeat 14px center;
}

::v-deep .el-input.login-ma .el-input__inner {
  padding-top: 2px;
  background: url("../assets/image/yanzma.png") no-repeat 14px center;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .box {
    padding: 100px;
  }
}

// @media screen and (min-width: 1024px) {
//   .box {
//     top: 17%;
//     width: 20%;
//     right: 15%;
//     height: 484px;
//     padding-bottom: 44px;

//     .register-border {
//       .span {
//         margin-top: 63px;
//         margin-bottom: 42px;
//       }
//     }
//   }
// }

.footer {
  font-size: 14px;
  color: #ecf5ff;

  p {
    width: 400px;
    text-align: center;
  }
}
</style>

<style lang="scss">
// 平板适配
@media screen and (max-width: 1280px) {

  .box {
    height: 230px;
    // height: 40vh;
    padding: 39px 30px !important;
    top: 49% !important;

    .register-border {
      margin-top: -30px !important;

      .el-form-item {
        margin-bottom: 15px !important;

        .el-form-item__content,
        .el-input {
          height: 33px !important;
        }

        input {
          height: 30px !important;
        }

        .code {
          top: 21% !important;
          height: 22px !important;
          line-height: 22px !important;

          span {
            font-size: 14px !important;
          }
        }
      }

      p {
        margin-bottom: 10px !important;
      }

      button {
        height: 40px !important;

      }

      .span {
        margin-bottom: 0px !important;
      }
    }
  }

  .diyLogo {
    img {
      margin: 0px 5px !important;
    }

    & > :first-child {
      margin-left: 4% !important;
    }
  }
}
</style>
